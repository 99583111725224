@font-face {
  font-family: 'Calibri';
  font-style: normal;
  font-weight: 200;
  src: local('Calibri'),
    url(https://fonts.gstatic.com/l/font?kit=J7afnpV-BGlaFfdAhLEY67FIEjg&skey=a1029226f80653a8&v=v10)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

.componentsMenu {
  width: 100%;
  align-content: center;
  background: #f4f4f4 0% 0% no-repeat padding-box;
  padding: 20px;
  border-right: none;
}

.componentsMenu ul {
  list-style: none;
}

.componentsMenu li {
  float: left;
  margin: 5px;
  -webkit-box-shadow: 0px 3px 6px #dbdbdb;
  -moz-box-shadow: 0px 3px 6px #dbdbdb;
  box-shadow: 0px 3px 6px #dbdbdb;
  transition: 0.2s ease all;
  font: normal normal 300 30px/44px Calibri, sans-serif;
}

.componentsMenu li:hover {
  transform: scale(1.06);
}

.headline {
  text-transform: uppercase;
  font: normal normal 300 30px/44px Calibri, sans-serif;
  font-size: 1.6em;
  width: 100%;
  margin-left: 22px;
  margin-bottom: -7px;
  position: sticky;
  top: 0;
  z-index: 1;
  background: rgb(244, 244, 244);
  padding-top: 4px;
}

.componentsMainmenu {
  width: 100%;
  border-right: none;
  background: #f4f4f4 0% 0% no-repeat padding-box;
}

.ant-layout-sider-children {
  height: auto;
}
