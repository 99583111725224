.propertyHeadline {
  text-transform: uppercase;
  font: normal normal 300 30px/44px Calibri, sans-serif;
  font-size: 1.7em;
  width: 100%;
  margin-bottom: -7px;
}

.selectButton {
  width: 100%;
}

.picture {
  word-break: break-all !important;
  white-space: normal;
  height: inherit !important;
  padding: 5px;
}

.divider {
  align-items: center;
  height: 1px;
  margin-bottom: 8px;
  border-width: 3px;
}

.deleteButton:hover,
.deleteButton:focus {
  background-color: #51504e;
}

.deleteButton {
  border-width: 3px;
  margin-left: 5px;
  margin-right: 5px;
  background: #f4f4f4 0% 0% no-repeat padding-box;
}

.deleteButtonSpan {
  position: relative;
  top: -3px;
}

.deleteButtonContainer {
  bottom: 0;
  position: fixed;
  padding-top: 2em;
  height: 6em;
  width: 28vw;
  text-align: center;
  background: #f4f4f4 0% 0% no-repeat padding-box;
}

.listButton,
.listButton:focus,
.listButton:hover {
  height: 27px;
  width: 41px;
  padding: 0px;
  background: #f4f4f4 0% 0% no-repeat padding-box;
}

.listButtonSpan {
  position: relative;
  top: -2px;
}

.listRemoveButton {
  margin-left: 8px;
}

.upload {
  word-break: break-all !important;
  white-space: normal;
}

.MuiDropzoneArea-root.dropzone {
  min-height: 152px;
}

.rdw-editor-wrapper {
  background: white;
}

.rdw-editor-wrapper .rdw-editor-main {
  height: 400px;
}

.rdw-link-modal {
  height: 300px !important;
}

.rdw-link-modal-btn,
.rdw-embedded-modal-btn {
  height: 40px !important;
}

.rdw-embedded-modal {
  height: 230px !important;
}

.stringEditor,
.checkboxEditor,
.textEditor,
.radioEditor,
.listEditor,
.NumberEditor {
  display: block;
  margin-top: 0.4em;
}

.roleCheckbox .ant-checkbox-group-item {
  display:flex;
  margin-right: 0;
}

.ant-input.roleInput {
  width: calc(100% - 82px);
}

.listEditor {
  word-break: break-all !important;
  white-space: normal;
  margin-bottom: 0.4em;
}

.NumberEditor {
  width: 100%;
  background: #f4f4f4 0% 0% no-repeat padding-box;
  box-shadow: none;
  border-width: 0 0 2px;
  border-right-width: 0px !important;
}

.NumberEditor:hover {
  border-color: #d9d9d9 !important;
}

.booleanEditor {
  padding-left: 7px;
  margin-top: 0.4em;
  margin-bottom: 0.4em;
}

.radioEditorLi {
  line-height: 16px;
  word-break: break-all !important;
  white-space: normal;
  list-style: none;
}

.rdw-editor-compact > .rdw-editor-main {
  height: 100%;
}
