.header {
  background-color: #51504e;
  margin-top: -3px;
}

.headerUL {
  position: relative;
  left: -5em;
  cursor: pointer;
  list-style: none;
}

.headerUL li {
  float: left;
}

.headerUL li:first-child {
  margin-right: 10px;
}

.saveIcon {
  cursor: pointer;
  font-size: 0.8em;
  height: 3em;
  width: 8em;
  text-align: center;
  line-height: 0.8em;
  color: #93f100;
  border-radius: 0;
  transition: 0.2s ease all;
  border: 1px solid #93f100;
  background-color: transparent;
}

.saveIcon:hover {
  background-color: #93f100;
  color: #51504e;
  border-color: transparent;
}

.icon {
  cursor: pointer;
  height: 21px;
  width: 24px;
  transition: 0.2s ease all;
}

.icon:hover g {
  fill: #93f100;
}

.icon:hover .a {
  fill: #93f100;
}

.icon:hover path {
  stroke: #93f100;
}
