.ant-col-2 {
  flex: 0px;
  display: flex;
  justify-content: center;
}

.ant-input {
  outline: 0;
  border-width: 0 0 2px;
  background-color: white;
  padding: 0px;
}

.ant-input-suffix {
  margin-right: -14px;
}

.ant-input-password,
.ant-input-affix-wrapper,
.ant-input-affix-wrapper:hover {
  border-width: 0 0 2px;
  border-right-width: 0px !important;
  border-color: #d9d9d9;
  box-shadow: none !important;
  margin-left: -11px;
}

.input.ant-input {
  margin-left: -11px;
}

.ant-btn:hover,
.ant-btn:focus,
.ant-checkbox:hover,
.ant-checkbox-wrapper:hover,
.ant-checkbox:focus,
.ant-checkbox,
.ant-checkbox-checked,
.ant-checkbox-checked::after,
.ant-checkbox-inner,
.ant-checkbox-input:hover,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #b1b0af;
  color: #b1b0af;
}

.ant-checkbox-input:focus,
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #b1b0af;
  border-color: #b1b0af;
  color: #b1b0af;
}

.ant-input:hover {
  border-color: #b1b0af;
  border-right-width: 0px !important;
}

.ant-layout .ant-layout-has-sider {
  background-color: white;
}

.ant-input:focus {
  border-color: #7184a9;
  box-shadow: none;
  border-width: 0 0 2px;
  border-right-width: 0px !important;
}

.ant-select-selector,
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border-width: 0 0 2px !important;
  border-right-width: 0px !important;
  padding: 0px !important;
  box-shadow: none !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: white;
  border-right-width: 0px !important;
}

.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active {
  color: #51504e;
}

.ant-menu-item-selected {
  color: #51504e;
  border-right-width: 0px !important;
}

.ant-menu-inline .ant-menu-item::after {
  border-right: 0px;
}

.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0px;
}

.ant-menu-inline .ant-menu-item {
  margin-top: 0px;
}

.ant-select-selector:hover,
.ant-select-selector:focus {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}

.ant-input,
.ant-input-password,
.ant-input-affix-wrapper {
  margin-bottom: 0.4em;
  background: #f4f4f4 0% 0% no-repeat padding-box;
}

.ant-btn .anticon.anticon-minus > svg {
  shape-rendering: auto !important;
}

.ant-menu-inline .ant-menu-item {
  line-height: normal;
  margin-bottom: 0em;
}

.ant-divider-horizontal {
  margin: 0em;
}

.ant-input-number {
  width: 100%;
  background: #f4f4f4 0% 0% no-repeat padding-box;
}

.ant-input-number-focused {
  border-color: #d9d9d9 !important;
}

.ant-menu-submenu > .ant-menu {
  margin-left: 19px;
  margin-top: -5px;
  background: #f4f4f4 0% 0% no-repeat padding-box;
}
.ant-card-body {
  padding: 24px 3px 9px 3px;
}

.ant-menu-submenu > .ant-menu {
  margin-top: 0.5em;
  margin-left: 0em;
  padding-top: 0.5em;
  padding-left: 1.5em;
}

.ant-tabs {
  overflow: inherit;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
  margin-right: -13px !important;
  border-radius: 5px 32px 0px 0 !important;
}

.ant-tabs-nav-add {
  border-radius: 0px 23px 0px 0 !important;
  margin-left: 5px;
}

.ant-tabs-tab {
  margin-left: 1em;
  margin-right: 0.5em;
}

.ant-tabs-tab.ant-tabs-tab-with-remove.ant-tabs-tab-active {
  z-index: 1;
}

.ant-tabs-nav {
  margin-right: -31px !important;
  background: rgb(244, 244, 244);
}

.ant-tooltip-inner {
  color: #51504e;
  background-color: #f4f4f4;
}

/**
* Customize Antd Collapse
*/
.ant-collapse,
.ant-collapse-content > .ant-collapse-content-box,
.ant-collapse-item:last-child > .ant-collapse-content {
  background-color: #00000000;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding-top: 0em;
  padding-left: 1.5em;
  padding-bottom: 0.5em;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: inherit;
}
