.tab-list-formbuilder {
  position: absolute;
  width: 100%;
}

.tab-list-confirmationpage {
  position: absolute;
  left: 0;
  top: 103px;
  width: calc(100% - 50px);
  margin: 0 25px;
  overflow: hidden;
  background: rgb(244, 244, 244);
}

.tabButton {
  margin-right: 31px;
  height: 38px;
  border-radius: 23px 3px 0px 0;
  background: #fafafa;
  outline: none !important;
  border: 0 !important;
  box-shadow: none;
}

.tabButton:focus {
  height: 40px;
  color: #1890ff !important;
  background: white !important;
  text-decoration: none !important;
  outline: none !important;
  box-shadow: none;
}

.tabs {
  background: white;
  margin: 25px;
  margin-top: 48px;
}

.tabs_dependenciesMode {
  background: white;
  margin: 25px;
  margin-top: 48px;
  margin-left: 120px;
}

.footerTab {
  background: white;
  margin: 25px;
  margin-top: -20px;
}

.footerTab > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list {
  background-color: white;
  border-radius: 5px 32px 0px 0 !important;
  width: 73px;
}
.footerTab
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab.ant-tabs-tab-active {
  margin-left: 13px;
}

.tab-list-confirmationpage .ant-card.ant-card-bordered {
  margin: -2px -2px 0 -2px;
  padding-top: 1px;
}

.tab-list-confirmationpage .ant-card-body {
  padding: 0px;
}
